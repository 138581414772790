var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{attrs:{"aria-role":"list","scrollable":"","max-height":"240px","position":"is-top-right"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_vm._t("trigger",null,{"active":active})]}}],null,true)},[[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","custom":""}},[_c('div',{staticClass:"link-images-wrapper"},[_c('h5',{staticStyle:{"margin-bottom":"16px"}},[_vm._v(_vm._s(_vm.$t('link-images')))]),_c('b-field',{attrs:{"horizontal":"","label":_vm.$t('link-mode')}},[_c('b-select',{attrs:{"size":"is-small","expanded":""},model:{value:(_vm.linkMode),callback:function ($$v) {_vm.linkMode=$$v},expression:"linkMode"}},_vm._l((_vm.modeOptions),function(option){return _c('option',{key:option.label,domProps:{"value":option.key}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)],1),(_vm.linkedIndexes)?_c('div',{staticClass:"current-group"},[_c('p',[_vm._v(_vm._s(_vm.$t('view-linked-with')))]),_c('ul',_vm._l((_vm.linkedIndexes),function(index){return _c('li',{key:index},[_c('i',{staticClass:"fas fa-caret-right"}),_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: _vm.imagesWithNum[index].number}))+" ("),_c('image-name',{attrs:{"image":_vm.imagesWithNum[index].image}}),_vm._v(") "),_c('br')],1)}),0),_c('button',{staticClass:"button is-small",on:{"click":function($event){return _vm.unlink()}}},[_vm._v(_vm._s(_vm.$t('button-unlink')))])]):_vm._e(),(_vm.otherGroups.length || _vm.otherSoloImages.length)?[(_vm.linkedIndexes)?_c('p',[_vm._v(_vm._s(_vm.$t('link-other-images-to-this-group')))]):_c('p',[_vm._v(_vm._s(_vm.$t('link-view-with')))]),_vm._l((_vm.otherGroups),function(ref){
var images = ref.images;
var index = ref.index;
var number = ref.number;
return _c('p',{key:("group" + number)},[_c('b-checkbox',{attrs:{"type":"is-info","value":false},nativeOn:{"change":function($event){return (function (event) { return _vm.handleCheckboxChange(event, index); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('link-group', {number: number}))+" "),_c('ul',{staticClass:"group"},_vm._l((images),function(indexImage){return _c('li',{key:indexImage},[_c('i',{staticClass:"fas fa-caret-right"}),_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: _vm.imagesWithNum[indexImage].number}))+" ("),_c('image-name',{attrs:{"image":_vm.imagesWithNum[indexImage].image}}),_vm._v(") "),_c('br')],1)}),0)])],1)}),_vm._l((_vm.otherSoloImages),function(ref){
var image = ref.image;
var index = ref.index;
var number = ref.number;
return _c('p',{key:index},[_c('b-checkbox',{attrs:{"type":"is-info","value":false},nativeOn:{"change":function($event){return (function (event) { return _vm.handleCheckboxChange(event, null, index); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('viewer-view', {number: number}))+" ("),_c('image-name',{attrs:{"image":image}}),_vm._v(") ")],1)],1)})]:_vm._e()],2)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }