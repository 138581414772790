var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cytomine-modal',{attrs:{"active":_vm.active,"title":_vm.$t('add-images')},on:{"close":function($event){return _vm.$emit('update:active', false)}}},[_c('b-loading',{staticClass:"small",attrs:{"is-full-page":false,"active":_vm.loading}}),(!_vm.loading)?[[_c('div',{staticClass:"row"},[_c('b-input',{staticClass:"search-images",attrs:{"placeholder":_vm.$t('search-placeholder'),"type":"search","pack":"mdi","icon":"magnify"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('cytomine-multiselect',{attrs:{"id":"storage-select","options":_vm.storages,"label":"name","track-by":"id","allow-empty":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(" "+_vm._s(option.name)+" "),(_vm.currentUser.isDeveloper)?[_vm._v(" ("+_vm._s(_vm.$t('id'))+": "+_vm._s(option.id)+") ")]:_vm._e()]}}],null,false,593620560),model:{value:(_vm.selectedStorage),callback:function ($$v) {_vm.selectedStorage=$$v},expression:"selectedStorage"}})],1),_c('cytomine-table',{attrs:{"collection":_vm.imageCollection,"currentPage":_vm.currentPage,"perPage":_vm.perPage,"sort":_vm.sortField,"order":_vm.sortOrder,"detailed":false},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"update:sort":function($event){_vm.sortField=$event},"update:order":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-table-column',{attrs:{"label":_vm.$t('overview')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var image = ref.row;
return [_c('img',{staticClass:"image-overview",attrs:{"src":image.previewURL(256)}})]}}],null,false,1395410274)}),_c('b-table-column',{attrs:{"field":"originalFilename","label":_vm.$t('name'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var image = ref.row;
return [_vm._v(" "+_vm._s(image.originalFilename)+" ")]}}],null,false,2596161687)}),_c('b-table-column',{attrs:{"field":"created","label":_vm.$t('created-on'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var image = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("moment")(Number(image.created),'ll LT'))+" ")]}}],null,false,1764328432)}),_c('b-table-column',{attrs:{"label":" ","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var image = ref.row;
return [(_vm.wasAdded(image))?_c('button',{staticClass:"button is-small is-light",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('button-added'))+" ")]):(_vm.isInProject(image))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('already-in-project'))+" ")]):_c('button',{staticClass:"button is-small is-light",on:{"click":function($event){return _vm.addImage(image)}}},[_vm._v(" "+_vm._s(_vm.$t('button-add'))+" ")])]}}],null,false,1222605740)})]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v(_vm._s(_vm.$t('no-image')))])])]},proxy:true}],null,false,1559079466)})]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }