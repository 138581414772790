var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel"},[_c('p',{staticClass:"panel-heading"},[_c('span',[_c('icon-base',[_c('icon-password')],1),_vm._v(" "+_vm._s(_vm.$t('button-reset-password'))+" ")],1),_vm._m(0)]),_c('div',{staticClass:"panel-block"},[_c('form',{attrs:{"data-vv-scope":"password"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword()}}},[_c('b-field',{attrs:{"label":_vm.$t('username')}},[_c('b-input',{attrs:{"name":"username","value":_vm.username,"disabled":""}})],1),_c('b-field',{attrs:{"label":_vm.$t('password-new'),"type":{'is-danger': _vm.errors.has('password.newPassword')}}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate.continues",value:({
            required: true,
            length:[8, 20],
            oneUppercase: true,
            oneLowercase: true,
            oneSpecialChar: true,
            oneNum: true,
            noSpace: true
          }),expression:"{\n            required: true,\n            length:[8, 20],\n            oneUppercase: true,\n            oneLowercase: true,\n            oneSpecialChar: true,\n            oneNum: true,\n            noSpace: true\n          }",modifiers:{"continues":true}}],attrs:{"type":"password","name":"newPassword","maxlength":"20","password-reveal":""},on:{"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('b-field',{attrs:{"label":""}},[_c('small',{staticClass:"help",class:{'is-hidden': !_vm.isFocused}},[_c('ul',_vm._l((_vm.passwordValidationRules),function(rule,id){return _c('li',{key:id,class:_vm.getMessageClass(rule)},[_vm._v(" "+_vm._s(_vm.getMessageSymbol(rule))+" "),(rule === 'length')?[_vm._v(" "+_vm._s(_vm.$t('validations.messages.'+ rule, {1: 8, 2: 20}))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('validations.messages.'+ rule))+" ")]],2)}),0)])]),_c('b-field',{attrs:{"label":_vm.$t('password-confirm'),"type":{'is-danger': _vm.errors.has('password.confirmPassword')},"message":_vm.errors.first('password.confirmPassword')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({required: true, is: _vm.newPassword}),expression:"{required: true, is: newPassword}"}],attrs:{"type":"password","name":"confirmPassword"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column has-text-right"},[_c('button',{class:['button', 'is-link', _vm.loading ? 'is-loading' : ''],attrs:{"disabled":_vm.errors.any()}},[_vm._v(" "+_vm._s(_vm.$t('button-reset-password'))+" ")])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"panel-heading__logo",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/logo/logo_web.svg"),"alt":"SlideVault"}})])}]

export { render, staticRenderFns }