const icons = {
  /* Icon Base */
  'icon-base': () => import('../components/icons/IconBase.vue'),

  /* Icon / Action */
  'icon-add':       () => import('../components/icons/action/IconAdd.vue'), // plus
  'icon-download':  () => import('../components/icons/action/IconDownload.vue'),
  'icon-filter':    () => import('../components/icons/action/IconFilter.vue'),
  'icon-logout':    () => import('../components/icons/action/IconLogout.vue'),
  'icon-remove':    () => import('../components/icons/action/IconRemove.vue'), // minus
  'icon-search':    () => import('../components/icons/action/IconSearch.vue'),
  'icon-upload':    () => import('../components/icons/action/IconUpload.vue'),
  
  // actions for review in viewer
  'icon-block':     () => import('../components/icons/action/IconBlock.vue'),
  'icon-check':     () => import('../components/icons/action/IconCheck.vue'),
  'icon-like':      () => import('../components/icons/action/IconLike.vue'),
  'icon-pause':     () => import('../components/icons/action/IconPause.vue'),
  
  /* Icon / Common */
  'icon-activity-history':  () => import('../components/icons/common/IconActivityHistory.vue'),
  'icon-attached-file':     () => import('../components/icons/common/IconAttachedFile.vue'),
  'icon-calendar':          () => import('../components/icons/common/IconCalendar.vue'),
  'icon-comment':           () => import('../components/icons/common/IconComment.vue'),
  'icon-file':              () => import('../components/icons/common/IconFile.vue'),
  'icon-home':              () => import('../components/icons/common/IconHome.vue'),
  'icon-info':              () => import('../components/icons/common/IconInfo.vue'),
  'icon-link':              () => import('../components/icons/common/IconLink.vue'),
  
  // for user
  'icon-user':              () => import('../components/icons/common/IconUser.vue'),
  'icon-user-admin':        () => import('../components/icons/common/IconUserAdmin.vue'),
  'icon-user-supervisor':   () => import('../components/icons/common/IconUserSupervisor.vue'),
  'icon-password':          () => import('../components/icons/common/IconPassword.vue'),
  'icon-key':               () => import('../components/icons/common/IconKey.vue'),
  // for member role legend; use without icon-base
  'icon-project-member-role': () => import('../components/icons/common/IconProjectMemberRole.vue'),

  // for global nav
  'icon-project':   () => import('../components/icons/common/IconProject.vue'),
  'icon-storage':   () => import('../components/icons/common/IconStorage.vue'),
  'icon-hash':      () => import('../components/icons/common/IconHash.vue'), // for ontology term
  'icon-algorithm': () => import('../components/icons/common/IconAlgorithm.vue'),

  // for project sidebar nav
  'icon-image':         () => import('../components/icons/common/IconImage.vue'),
  'icon-activity':      () => import('../components/icons/common/IconActivity.vue'),  
  'icon-configuration': () => import('../components/icons/common/IconConfiguration.vue'),

  // for notification
  'icon-notification':  () => import('../components/icons/common/IconNotification.vue'),

  /* Icon / Navigation */
  'icon-arrow-left':                      () => import('../components/icons/navigation/IconArrowLeft.vue'),
  'icon-arrow-right':                     () => import('../components/icons/navigation/IconArrowRight.vue'),
  'icon-arrow-bottom-left':               () => import('../components/icons/navigation/IconArrowBottomLeft.vue'),
  'icon-arrow-bottom-right':              () => import('../components/icons/navigation/IconArrowBottomRight.vue'),
  'icon-arrow-top-left':                  () => import('../components/icons/navigation/IconArrowTopLeft.vue'),
  'icon-arrow-top-right':                 () => import('../components/icons/navigation/IconArrowTopRight.vue'),
  'icon-close':                           () => import('../components/icons/navigation/IconClose.vue'),
  'icon-close-fullscreen':                () => import('../components/icons/navigation/IconCloseFullscreen.vue'),
  'icon-open-fullscreen':                 () => import('../components/icons/navigation/IconOpenFullscreen.vue'),
  'icon-chevron-down':                    () => import('../components/icons/navigation/IconChevronDown.vue'),
  'icon-chevron-left':                    () => import('../components/icons/navigation/IconChevronLeft.vue'),
  'icon-chevron-right':                   () => import('../components/icons/navigation/IconChevronRight.vue'),
  'icon-chevron-up':                      () => import('../components/icons/navigation/IconChevronUp.vue'),
  'icon-chevron-double-left':             () => import('../components/icons/navigation/IconChevronDoubleLeft.vue'),
  'icon-chevron-double-right':            () => import('../components/icons/navigation/IconChevronDoubleRight.vue'),
  'icon-chevron-double-top-right':        () => import('../components/icons/navigation/IconChevronDoubleTopRight.vue'),
  'icon-chevron-double-bottom-left':      () => import('../components/icons/navigation/IconChevronDoubleBottomLeft.vue'),
  'icon-menu':                            () => import('../components/icons/navigation/IconMenu.vue'),
  'icon-menu-dot-horizontal':               () => import('../components/icons/navigation/IconMenuDotHorizontal.vue'),

  /* Icon / Workspace */
  'icon-annotation-manual':   () => import('../components/icons/workspace/IconAnnotationManual.vue'),
  'icon-annotation-analyzed': () => import('../components/icons/workspace/IconAnnotationAnalyzed.vue'),
  'icon-annotation-reviewed': () => import('../components/icons/workspace/IconAnnotationReviewed.vue'),
  'icon-annotation-details':  () => import('../components/icons/workspace/IconAnnotationDetails.vue'),
  // color configured
  'icon-lagotto': () => import('../components/icons/workspace/IconLagotto.vue'),
  /* Icon / Workspace / Configuration - Custom UI page */
  'icon-all-elements':  () => import('../components/icons/workspace/config-custom-ui/IconAllElements.vue'),
  'icon-description':   () => import('../components/icons/workspace/config-custom-ui/IconDescription.vue'),
  'icon-slide-preview': () => import('../components/icons/workspace/config-custom-ui/IconSlidePreview.vue'),
  'icon-tag':           () => import('../components/icons/workspace/config-custom-ui/IconTag.vue'),
  'icon-track':         () => import('../components/icons/workspace/config-custom-ui/IconTrack.vue'),
  'icon-geometry-info': () => import('../components/icons/workspace/config-custom-ui/IconGeometryInfo.vue'),
  
  /* Icon / Workspace / Viewer */
  'icon-tray':            () => import('../components/icons/workspace/viewer/IconTray.vue'),
  'icon-grid':            () => import('../components/icons/workspace/viewer/IconGrid.vue'),
  // for rotation
  'icon-rotate':          () => import('../components/icons/workspace/viewer/IconRotate.vue'), 
  'icon-rotate-cw':       () => import('../components/icons/workspace/viewer/IconRotateCw.vue'), 
  'icon-rotate-ccw':      () => import('../components/icons/workspace/viewer/IconRotateCcw.vue'), 

  /* Icon / Workspace / Viewer / Panels */
  'icon-broadcast':     () => import('../components/icons/workspace/viewer-panels/IconBroadcast.vue'),
  'icon-color':         () => import('../components/icons/workspace/viewer-panels/IconColor.vue'),
  'icon-digital-zoom':  () => import('../components/icons/workspace/viewer-panels/IconDigitalZoom.vue'), 
  'icon-layer':         () => import('../components/icons/workspace/viewer-panels/IconLayer.vue'),
  'icon-property':      () => import('../components/icons/workspace/viewer-panels/IconProperty.vue'),
  'icon-analysis-image-search':  () => import('../components/icons/workspace/viewer-panels/IconAnalysisImageSearch.vue'),
  'icon-analysis-mitotic-count': () => import('../components/icons/workspace/viewer-panels/IconAnalysisMitoticCount.vue'),

  /* Icon / Workspace / Viewer / Tools */
  'icon-tool-select':           () => import('../components/icons/workspace/viewer-tools/IconToolSelect.vue'),
  'icon-tool-select-patch':     () => import('../components/icons/workspace/viewer-tools/IconToolSelectPatch.vue'),
  'icon-tool-ruler':            () => import('../components/icons/workspace/viewer-tools/IconToolRuler.vue'),
  // undo & redo
  'icon-tool-undo':             () => import('../components/icons/workspace/viewer-tools/IconToolUndo.vue'),
  'icon-tool-redo':             () => import('../components/icons/workspace/viewer-tools/IconToolRedo.vue'),
  // draw/edit tools
  'icon-tool-draw-annotation':  () => import('../components/icons/workspace/viewer-tools/IconToolDrawAnnotation.vue'),
  'icon-tool-edit-annotation':  () => import('../components/icons/workspace/viewer-tools/IconToolEditAnnotation.vue'),
};

export default icons;